import { Component, OnInit } from '@angular/core';
import { UserService } from '../user.service';
import { from } from 'rxjs';
import { Router } from '@angular/router';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

    data:any = {};
    //public token;
    public tempToken;
    public test:any=[];
    
  constructor(private userService: UserService,private _router: Router) { }

  ngOnInit() {
  }
  login(){
   
    this.userService.login(this.data.email,this.data.password).subscribe(res=>{
     
  
  this.test=res;
  //console.warn(this.test.token);
  
   console.log(this.test);
  
   localStorage.setItem('token',this.test.token);
   if(this.test.token!=undefined){
    this._router.navigate(['/booklist']);
   }
   else{
    
     this._router.navigate(['/login']);
    

   }
   
    },err=>{
      console.log("error is",err);
      console.log(err.statusText);
      alert(err.statusText);
    })
    
    
}

isLoggedIn(): boolean {
    
  this.tempToken = localStorage.getItem('token');
  console.log(this.tempToken);
  //console.error(this.tempToken);
  if (this.tempToken != null) {

    return true;
  } else {
    localStorage.clear();
    this._router.navigateByUrl('/booklist');
    return false;
  }
}

}
