import { Component, OnInit } from '@angular/core';
import { UserService } from '../user.service';
@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css']
})
export class RegisterComponent implements OnInit {
  public test:any=[];
   data: any ={'email':'','password':''};
  constructor(private userService:UserService) { }

   register(){
    //  this.userService.create(this.data).subscribe(res=>{
    //    console.log(res);
       
    //  })
         this.userService.create(this.data).subscribe(res=>{
          this.test=res;
           //console.log(res);
           //console.log(this.test.status);
           alert(this.test.message);
          
         },err=>{
          console.log("error is",err);
          console.log(err.statusText);
          alert(err.statusText);
        }); 
         
          
   }
  ngOnInit() {
  }

}
